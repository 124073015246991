:root {
    --app-height: 100%;
    --text-color: #000000;
    --second-text-color: #6E7591;
    --placeholder: #c4c4c4;
    --main-color: #4B5320;
    --white-color: #FFFFFF;
    --disable-color: #e3e3e3;
    --border-color: #4B5320;
    --error-color: #fc001f;
}

/*background: linear-gradient(180.02deg, #FFFFFF 0.01%, rgba(112, 130, 56, 0.15) 95.55%);*/

html,
body {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
}

input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 10px;
}

button:hover {
    cursor: pointer
}

button:active {
    opacity: 0.5
}

.ant-picker-range-wrapper {
    margin: 0 auto !important;
}

.ant-picker-panels {
    flex-direction: column !important;
}

.ant-picker-input input {
    text-align: center;
}

.ant-picker-presets {
    min-width: 100px !important;
    max-width: 150px !important;
}

.ant-select .ant-select-selector {
    width: 100%;
    border: none !important;
}

.ant-select-focused {
    box-shadow: none !important;
}
